@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

body {
  margin: 0px;
  background-color: rgb(242, 242, 242);
  overflow: visible;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 90%;
  }
}

.centerBlock {
  width: 1170px;
  margin: auto;
}

.wgcBody {
  margin: 0px;
}

.mat-icon{
  user-select:none;
}

.flex{
  display:flex;
}
