@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$wgc-primary: mat-palette($mat-red, 700, 600, A700);
$wgc-accent: mat-palette($mat-grey);
// The warn palette is optional (defaults to red).
$wgc-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$wgc-theme: mat-light-theme($wgc-primary, $wgc-accent, $wgc-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($wgc-theme);
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.centerBlock {
  width: 1170px;
  margin: auto;
}

.wgcBody {
  margin: 0px;
}
.mat-progress-bar-buffer{
  background-color: #fbeded;
}
